import React from "react"
import Header from "../components/header";

const ContactPage = () => {
    return (
        <div>
            <Header />
            <p>Contact vefside.com</p>
        </div>
    );
};

export default ContactPage;